
import { Component, Ref, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { ElTable } from 'element-ui/types/table'
import { DeviceInfo } from '@/types/ecologyMonitor'
import { MenuItem } from '@/types/menu'

interface Table extends ElTable {
  selection: DeviceInfo[];
}

@Component
export default class Device extends Vue {
  @Ref('tables') readonly elTable!: Table
  @Ref('allocateForm') readonly elForm!: ElForm
  private searchInfo = {
    deviceName: '',
    deviceNumber: '',
    projectId: '',
    deviceStatus: '',
    allotStatus: ''
  }

  private typeList = [
    {
      name: '智慧水表',
      showType: false
    },
    {
      name: '智慧断路器',
      showType: false
    },
    {
      name: '水压监测',
      showType: false
    },
    {
      name: '割草机器人',
      showType: false
    }
  ]

  private deviceType: number | null = null
  private page = 1
  private size = 10
  private total = 0
  private tableData = []
  private loading = false
  private showAllocateDialog = false
  private allocateLoading = false
  allocateInfo: {deviceNumber: string[]; projectId: string} = {
    deviceNumber: [],
    projectId: ''
  }

  // 土壤配置弹窗
  private finds = []
  private soilDialog = false

  allocateRules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ]
  }

  private tableTR = [
    // 土壤
    {
      label: '预设割草面积（m²）',
      prop: 'mowingArea',
      type: 3,
      normal: true,
      minWidth: 150
    },
    {
      label: '预设割草时间',
      prop: 'mowingTime',
      type: 3,
      normal: true,
      minWidth: 180
    },
    {
      label: '运行状态',
      prop: 'runState',
      type: 3,
      normal: true,
      minWidth: 80
    }
  ]

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  get selection () {
    return this.elTable.selection
  }

  created () {
    const data = sessionStorage.getItem('childNode') || ''
    this.getShowType(JSON.parse(data))
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  // 编辑后重新获取数据
  activated () {
    if (this.deviceType !== -1) {
      this.getData()
    }
  }

  getShowType (data: MenuItem) {
    if (data.childNode && data.childNode.length > 0) {
      data.childNode.forEach(item => {
        this.typeList.forEach((items) => {
          if (item.menuName === items.name) {
            items.showType = true
          }
        })
      })
      this.deviceType = this.typeList.findIndex(item => item.showType)
      console.log(this.deviceType)
      if (this.deviceType !== -1) {
        this.getData()
      }
    }
  }

  getData () {
    this.loading = true
    const urlList = [
      '',
      '',
      '',
      this.$apis.maintainJobMonitor.selectMowingInfoByPage
    ]
    const data = ['', '', '', '']
    this.$axios.get(urlList[Number(this.deviceType)], {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then(res => {
      if (data[Number(this.deviceType)]) {
        //
        this.total = res[data[Number(this.deviceType)]].total || 0
        this.tableData = res[data[Number(this.deviceType)]].list || []
      } else {
        // 割草机
        this.total = res.total || 0
        this.tableData = res.list || []
      }
      this.finds = res.finds || []
    }).finally(() => {
      this.loading = false
    })
  }

  changeType (index: number) {
    this.deviceType = index
    this.tableData = []
    this.searchData()
  }

  // 判断展示字段
  getTableTRList () {
    return this.tableTR.filter(item => {
      if (item.normal) {
        return true && item.type === this.deviceType
      } else {
        return this.finds.find((items) => items === item.prop) && item.type === this.deviceType
      }
    })
  }

  // 批量分配
  batchAllocate () {
    if (this.selection.length) {
      const isAllot = this.selection.find((item) => item.allotStatus === '1')
      if (isAllot) {
        this.$confirm('勾选项有设备已分配, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.showAllocateDialog = true
          this.allocateInfo.deviceNumber = this.selection.map(item => item.deviceNumber)
        })
      } else {
        this.showAllocateDialog = true
        this.allocateInfo.deviceNumber = this.selection.map(item => item.deviceNumber)
      }
    } else {
      this.$message.error('请选择要分配的设备')
    }
  }

  // 单个分配
  allocate (row: DeviceInfo) {
    if (row.allotStatus === '1') {
      this.$confirm('该设备已分配, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.showAllocateDialog = true
        this.allocateInfo.deviceNumber = [row.deviceNumber]
        this.allocateInfo.projectId = row.projectId || ''
      })
    } else {
      this.showAllocateDialog = true
      this.allocateInfo.deviceNumber = [row.deviceNumber]
    }
  }

  // 分配数据校验
  allocateDevice () {
    this.elForm.validate(valid => {
      if (valid) {
        this.doAllocateDevice()
      }
    })
  }

  // 分配执行
  doAllocateDevice () {
    this.allocateLoading = true
    const urlList = [
      this.$apis.maintainJobMonitor.updateMowingInfo,
      this.$apis.maintainJobMonitor.updateMowingInfo,
      this.$apis.maintainJobMonitor.updateMowingInfo,
      this.$apis.maintainJobMonitor.updateMowingInfo
    ]
    this.$axios.post(urlList[Number(this.deviceType)], this.allocateInfo).then(() => {
      this.$message.success('分配成功')
      this.elTable.clearSelection()
      this.getData()
    }).finally(() => {
      this.allocateLoading = false
      this.showAllocateDialog = false
      this.allocateInfo.projectId = ''
    })
  }

  // 运行状态
  getState (state: string) {
    const stateList = ['割草', '待机', '回仓', '休眠等待', '充电', '故障']
    return stateList[Number(state)]
  }

  toDetail (deviceNumber: string) {
    this.$router.push('/maintainJobMonitor/device/deviceDetail?deviceNumber=' + deviceNumber + '&deviceType=' + this.deviceType)
  }

  // 历史数据
  toHistory (deviceNumber: string) {
    const nameList = ['']
    this.$router.push({ name: nameList[Number(this.deviceType)], params: { deviceNumber: deviceNumber } })
  }
}
